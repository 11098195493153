import { useState, useMemo } from 'preact/hooks';
import { RATING_DESC } from '../../../../constants';
import './style.css';

const StarRating = ({ id, ratingScore, setRatingScore }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const getFillColor = (index) => {
    if (hoverRating >= index) {
      return '#36aff9';
    }
    if (!hoverRating && ratingScore >= index) {
      return '#36aff9';
    }
    return '#0000';
  };

  const getStrokeColor = (index) => {
    if (hoverRating >= index) {
      return '#36aff9';
    }
    if (!hoverRating && ratingScore >= index) {
      return '#36aff9';
    }
    return '#6873a2';
  };

  const starRating = useMemo(() => {
    return Array(5)
      .fill(0)
      .map((_, i) => i + 1)
      .map((idx) => (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          key={idx}
          onClick={() => setRatingScore(idx, id)}
          style={{ fill: getFillColor(idx), stroke: getStrokeColor(idx) }}
          onMouseEnter={() => setHoverRating(idx)}
          onMouseLeave={() => setHoverRating(0)}
        >
          <path
            d="M18.3065 4.68002L20.6532 9.37335C20.9732 10.0267 21.8265 10.6534 22.5465 10.7734L26.7999 11.48C29.5199 11.9334 30.1599 13.9067 28.1999 15.8534L24.8932 19.16C24.3332 19.72 24.0265 20.8 24.1999 21.5734L25.1465 25.6667C25.8932 28.9067 24.1732 30.16 21.3065 28.4667L17.3199 26.1067C16.5999 25.68 15.4132 25.68 14.6799 26.1067L10.6932 28.4667C7.83988 30.16 6.10655 28.8934 6.85321 25.6667L7.79988 21.5734C7.97321 20.8 7.66655 19.72 7.10655 19.16L3.79988 15.8534C1.85321 13.9067 2.47988 11.9334 5.19988 11.48L9.45321 10.7734C10.1599 10.6534 11.0132 10.0267 11.3332 9.37335L13.6799 4.68002C14.9599 2.13335 17.0399 2.13335 18.3065 4.68002Z"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ));
  }, [ratingScore, hoverRating]);

  const desc = useMemo(() => {
    if (hoverRating !== 0) {
      return RATING_DESC[hoverRating - 1];
    }
    return RATING_DESC[ratingScore - 1];
  }, [ratingScore, hoverRating]);

  return (
    <div className="rating">
      <div className="star-rating">{starRating}</div>
      <div className="description" id={id}>
        {desc}
      </div>
    </div>
  );
};

export default StarRating;
