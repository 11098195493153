import { createContext } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import { fetchSettings, sendFeedback } from '../../helpers/api';
import Header from '../../components/header';
import Details from '../../components/details';
import CsatIconsList from '../../components/csat-icons-list';
import Comment from '../../components/comment';
import SubmitButton from '../../components/submit-button';
import Footer from '../../components/footer';
import './style.css';
import { constants } from '../../helpers/constants';
import Loader from '../../components/loader';

export const HomeContext = createContext();

const Home = (props) => {
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [comment, setComment] = useState(null);
  const [selectedEmojiScore, setSelectedEmojiScore] = useState(null);
  const [theme, setTheme] = useState({
    color: null,
    title: null,
  });
  const [showLoader, setShowLoader] = useState(true);
  let { visitorToken, clientId } = props && props.matches;

  let sessionStorage = null;
  if (typeof window !== 'undefined') {
    sessionStorage = window.sessionStorage;
    sessionStorage.setItem('visitorToken', visitorToken);
    sessionStorage.setItem('clientId', clientId);
  }

  let roomId = '';
  useEffect(() => {
    // TODO: check whether csat already collected for visistor

    visitorToken = sessionStorage
      ? sessionStorage.getItem('visitorToken')
      : null;
    fetchSettings(visitorToken)
      .then((response) => {
        const parsedData = JSON.parse(response.data.data.result);
        roomId = parsedData.room && parsedData.room._id;
        const fetchedColor = parsedData.color;
        const fetchedTitle = parsedData.title;
        if (fetchedColor && fetchedTitle) {
          setTheme({
            color: fetchedColor,
            title: fetchedTitle,
          });
          sessionStorage
            ? sessionStorage.setItem('themeColor', fetchedColor)
            : null;
          sessionStorage
            ? sessionStorage.setItem('themeTitle', fetchedTitle)
            : null;
        }
        setShowLoader(false);
      })
      .catch((err) => {
        if (err.response) {
          route('/' + err.response.status, true);
          setShowLoader(false);
        } else {
          console.error('Error in fetching settings ', err);
          route('/404', true);
          setShowLoader(false);
        }
      });
  }, []);

  async function submitFeedbackDataAndRedirect(data) {
    const { score, comment } = data;
    const feedbackData = JSON.stringify({
      params: JSON.stringify([roomId, score, comment]),
    });
    sendFeedback(visitorToken, feedbackData);
    redirect();
  }

  function redirect() {
    route('/submit', true);
    sessionStorage ? sessionStorage.setItem('feedbackSubmitted', 'true') : null;
  }

  const isfeedbackSubmitted = sessionStorage
    ? sessionStorage.getItem('feedbackSubmitted')
    : null;
  if (isfeedbackSubmitted) {
    redirect();
  }

  return (
    <div className="outer">
      <Loader show={showLoader} />
      <Header theme={theme} />
      <HomeContext.Provider
        value={{
          selectedEmoji,
          setSelectedEmoji,
          comment,
          setComment,
          selectedEmojiScore,
          setSelectedEmojiScore,
        }}
      >
        <div className="feedback">
          <Details theme={theme} />
          <CsatIconsList />
          <Comment />
          <SubmitButton
            theme={theme}
            submitFeedbackDataAndRedirect={submitFeedbackDataAndRedirect}
          />
          <Footer />
        </div>
      </HomeContext.Provider>
    </div>
  );
};

export default Home;
