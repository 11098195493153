import style from './style.css';

const Details = (props) => {
  return (
    <div className={style.feedbackItem}>
      <h1
        className={`${style.feedbackHeader}`}
        style={{ color: props.theme.color }}
      >
        Rate your experience
      </h1>
      <p className={`${style.feedbackHeaderContent}`}>
        How would you rate our support experience?
      </p>
    </div>
  );
};

export default Details;
