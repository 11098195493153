import { useEffect, useState } from 'preact/hooks';
import './style.css';

const TextField = ({ fieldData, csatForm, setCSATForm, required }) => {
  const [textValue, setTextValue] = useState('');
  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    csatForm.FormFields.map((field) => {
      if (field.Id === fieldData.Id) {
        fieldData['value'] = textValue;
      }
      return csatForm;
    });
    setCSATForm(csatForm);
  }, [textValue]);

  useEffect(() => {
    setIsRequired(required.hasOwnProperty(fieldData.Id));
  }, [required]);

  return (
    <div className="textWrapper">
      <div className="title">
        {fieldData.FreeTextField.Title}
        {isRequired && <span className="required"> * required</span>}
        {!fieldData.IsMandatory ? <span> (optional)</span> : ''}
      </div>
      <textarea
        id={fieldData.FreeTextField.Id}
        rows={4}
        className="textarea"
        placeholder={fieldData.FreeTextField.Placeholder}
        maxLength={
          fieldData.FreeTextField.MaxCharacters === '0'
            ? undefined
            : fieldData.FreeTextField.MaxCharacters
        }
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
      />
    </div>
  );
};

export default TextField;
