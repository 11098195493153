import { useMemo, useState } from 'preact/hooks';
import { RATING_DESC } from '../../../../constants';
import {
  DisappointedFace,
  GrinningFace,
  NuetralFace,
  PoutingFace,
  SlightlySmilingFace,
} from './constants';
import './style.css';

const EmojiRating = ({ id, ratingScore, setRatingScore }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const desc = useMemo(() => {
    if (hoverRating !== 0) {
      return RATING_DESC[hoverRating - 1];
    }
    return RATING_DESC[ratingScore - 1];
  }, [ratingScore, hoverRating]);

  return (
    <div className="rating">
      <div className="emoji-rating">
        <div
          className={ratingScore === 1 ? 'active-emoji' : ''}
          onClick={() => setRatingScore(1, id)}
          onMouseEnter={() => setHoverRating(1)}
          onMouseLeave={() => setHoverRating(0)}
        >
          {PoutingFace()}
        </div>
        <div
          className={ratingScore === 2 ? 'active-emoji' : ''}
          onClick={() => setRatingScore(2, id)}
          onMouseEnter={() => setHoverRating(2)}
          onMouseLeave={() => setHoverRating(0)}
        >
          {DisappointedFace()}
        </div>
        <div
          className={ratingScore === 3 ? 'active-emoji' : ''}
          onClick={() => setRatingScore(3, id)}
          onMouseEnter={() => setHoverRating(3)}
          onMouseLeave={() => setHoverRating(0)}
        >
          {NuetralFace()}
        </div>
        <div
          className={ratingScore === 4 ? 'active-emoji' : ''}
          onClick={() => setRatingScore(4, id)}
          onMouseEnter={() => setHoverRating(4)}
          onMouseLeave={() => setHoverRating(0)}
        >
          {SlightlySmilingFace()}
        </div>
        <div
          className={ratingScore === 5 ? 'active-emoji' : ''}
          onClick={() => setRatingScore(5, id)}
          onMouseEnter={() => setHoverRating(5)}
          onMouseLeave={() => setHoverRating(0)}
        >
          {GrinningFace()}
        </div>
      </div>
      <div className="description" id={id}>
        {desc}
      </div>
    </div>
  );
};

export default EmojiRating;
