import './style.css';
import { Fragment } from 'preact';
// import Header from '../../components/header';

const NotFound = (props) => {
  const themeColor = window.sessionStorage.getItem('themeColor');
  const feedbackSubmitted = window.sessionStorage.getItem('feedbackSubmitted');
  const getIcon = (url) => {
    if(url === "/410" || url === "/submit") {
      return "/assets/icons/like.svg";
    } else {
      return "/assets/icons/link.svg";
    }
  };
  const getHeader = (url) => {
    if(url === "/410" || url === "/submit") {
      return "Thanks for rating us!";
    } else {
      return "Oops this link is expired!";
    }
  };
  const getText = (url) => {
    if(url === "/410" || url === "/submit") {
      return "We will continue to improve.";
    } else {
      return "This link to rate our support experience is expired.";
    }
  };
  return (
    <Fragment>
      <div className="outer">
        {/* <Header /> */}
        <div className="bottom">
          <div className="feedbackItem" />
          <div className="feedbackItem">
            <img
              id="like_img"
              src={getIcon(props.url)}
              className="likeImg"
              alt=""
            />
          </div>
          <div className="feedbackItem feedbackText">
            <div className="feedbackHeader" style={{ color: themeColor }}>
              {getHeader(props.url)}
            </div>
            <div className="feedbackDetail">{getText(props.url)}</div>
          </div>

          <div className="feedbackItem" />
        </div>
      </div>
    </Fragment>
  );
};

export default NotFound;
