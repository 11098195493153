import { useState, useMemo } from 'preact/hooks';
import { RATING_DESC } from '../../../../constants';
import './style.css';

const NumberRating = ({ id, ratingScore, setRatingScore }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const getFillColor = (index) => {
    if (hoverRating >= index) {
      return '#FFFFFF';
    }
    if (!hoverRating && ratingScore >= index) {
      return '#FFFFFF';
    }
    return '#6873A2';
  };

  const getStrokeColor = (index) => {
    if (hoverRating >= index) {
      return '#36aff9';
    }
    if (!hoverRating && ratingScore >= index) {
      return '#36aff9';
    }
    return '#F0F3FA';
  };

  const starRating = useMemo(() => {
    return Array(5)
      .fill(0)
      .map((_, i) => i + 1)
      .map((idx) => (
        <div
          className="number"
          key={idx}
          onClick={() => setRatingScore(idx, id)}
          style={{ color: getFillColor(idx), background: getStrokeColor(idx) }}
          onMouseEnter={() => setHoverRating(idx)}
          onMouseLeave={() => setHoverRating(0)}
        >
          {idx}
        </div>
      ));
  }, [ratingScore, hoverRating]);

  const desc = useMemo(() => {
    if (hoverRating !== 0) {
      return RATING_DESC[hoverRating - 1];
    }
    return RATING_DESC[ratingScore - 1];
  }, [ratingScore, hoverRating]);

  return (
    <div className="rating">
      <div className="number-rating">{starRating}</div>
      <div className="description" id={id}>
        {desc}
      </div>
    </div>
  );
};

export default NumberRating;
