import axios from 'axios';

function getBaseUrl() {
  if (typeof window === 'undefined') return '';
  const hostUrl = new URL(window.location.href);
  const clientId = window.sessionStorage.getItem('clientId');
  let baseDomain = 'verloop.io';
  let protocol = 'https://';
  if (hostUrl.hostname === 'localhost') {
    baseDomain = `local.${baseDomain}`;
    protocol = 'http://';
  } else if (hostUrl.searchParams.get('env')) {
    if (hostUrl.searchParams.get('env') !== 'prod') {
      baseDomain = `${hostUrl.searchParams.get('env')}.${baseDomain}`;
    }
  }

  if (!clientId) {
    throw new Error('No Client Id found');
  }
  const baseURL = `${protocol}${clientId}.${baseDomain}/api/public/methods`;
  return baseURL;
}

function sendRequest({ method, apiMethod, visitorToken, data }) {
  const baseURL = getBaseUrl();
  const requestOptions = {
    baseURL,
    url: `/${apiMethod}`,
    method,
    headers: {
      authorization: visitorToken,
      'Content-Type': 'application/json',
    },
    data,
  };
  return axios(requestOptions);
}

async function fetchSettings(visitorToken) {
  const data = JSON.stringify({ params: '[{}]' });

  const requestObj = {
    method: 'post',
    apiMethod: 'livechat-getSettingsForCsat',
    data,
    visitorToken,
  };
  return await sendRequest(requestObj);
}

async function sendFeedback(visitorToken, data) {
  const requestObj = {
    method: 'POST',
    apiMethod: 'livechat-submitFeedbackForCsat',
    data,
    visitorToken,
  };
  try {
    const res = await sendRequest(requestObj);
    console.log('feedback submitted succesfully!', res);
  } catch (err) {
    console.log('Failed to submit user feedback', err);
    throw new Error('Error: Failed to submit user feedback');
  }
}

export { fetchSettings, sendFeedback };
