import { ERROR_MSG } from '../Component/constants';

export class CustomError extends Error {
  constructor(msg, code) {
    super(msg);
    this.code = code;
  }
}

const getErrorResponse = (error) => {
  const errorData = error.response.data;
  const jsonString = String.fromCharCode.apply(null, new Uint8Array(errorData));
  const data = JSON.parse(jsonString);
  // status code of api response
  data.status = error.response.status;
  return data;
};

const catchError = (error) => {
  const errorData = getErrorResponse(error);
  const errMsg = errorData.message || errorData.msg;
  if (errMsg && errorData.status) {
    throw new CustomError(errMsg, errorData.status);
  }
  throw new CustomError(ERROR_MSG, 500);
};

export default catchError;
