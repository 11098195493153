import { Router } from 'preact-router';
import NotFound from '../pages/not-found';

// Code-splitting is automated for `routes` directory
import Home from '../pages/home';
import NewCsatForm from '../pages/NewCsatForm';

const App = () => (
  <div id="app">
    <Router>
      <Home path="/" />
      <NewCsatForm path="/api/csat/" />
      <NotFound default />
    </Router>
  </div>
);

export default App;
