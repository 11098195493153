import { createContext } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import Loader from '../../components/loader';
import CSATBody from '../../Component/CSATBody';
import { fetchSettings, GetCSATForm, getVisitorToken } from '../../api';

export const HomeContext = createContext();

const NewCsatForm = (props) => {
  const [showLoader, setShowLoader] = useState(true);
  const [CSATform, setCSATForm] = useState(null);
  let { id } = props && props.matches;
  const [kvstClientId, setClientId] = useState('');
  const [kvstVisitorToken, setVisitorToken] = useState('');
  const [kvstRoomId, setRoomId] = useState('');
  const [themeColor, setThemeColor] = useState('');

  useEffect(() => {
    if (id) {
      getVisitorToken(id)
        .then((resp) => {
          setVisitorToken(resp.visitor_token);
          setRoomId(resp.room_id);
          setClientId(resp.client_id);
          GetCSATForm(resp.room_id, resp.visitor_token, resp.client_id)
            .then((res) => {
              setCSATForm(res);
              setShowLoader(false);
            })
            .catch((err) => {
              if (err.code === 409) {
                route('/submit', true);
              } else {
                route('/404', true);
              }
              setShowLoader(false);
            });
        })
        .catch(() => {
          route('/404', true);
          setShowLoader(false);
        });
    }
  }, []);

  useEffect(() => {
    if (kvstVisitorToken && kvstClientId) {
      fetchSettings(kvstVisitorToken, kvstClientId)
        .then((response) => {
          const { color } = response;
          if (color) {
            setThemeColor(color);
          }
        })
        .catch(() => {
          setThemeColor('#d65500');
        });
    }
  }, [kvstVisitorToken, kvstClientId]);

  return (
    <>
      <Loader show={showLoader} />
      {CSATform && (
        <CSATBody
          form={CSATform}
          room={kvstRoomId}
          vt={kvstVisitorToken}
          clientId={kvstClientId}
          themeColor={themeColor}
        />
      )}
    </>
  );
};

export default NewCsatForm;
