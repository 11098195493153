/* eslint-disable no-param-reassign */
import { TwirpClient } from 'verloop/ts/twirp';
import {
  GetCSATFormRequest,
  SubmitCSATFormRequest,
} from 'verloop/ts/services/user_feedback_pb_vrlp';
import { UserFeedback } from 'verloop/ts/gateway/user_feedback_pb_twirp';
import { GetUserDataRequest } from 'verloop/ts/gateway/livechat_pb_vrlp';
import { Livechat } from 'verloop/ts/gateway/livechat_pb_twirp';
import catchError from './helper';

let Gateway = new TwirpClient('/gateway', false);

// SAMPLE_URL:- wl.verloop.io/api/csat?id=ss566ff565

export const getVisitorToken = (id) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  const raw = JSON.stringify({
    Id: id,
  });
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  return fetch(
    `${process.env.PREACT_CSAT_DEV_KVST_API_HOST}/api/v1/KVStore/Get`,
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data.Data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const getGatewayOptions = (visitorToken, clientId) => {
  const url = `https://${clientId}.${process.env.PREACT_BASE_URL}/gateway`;
  Gateway = new TwirpClient(url, false);
  Gateway.setOptionsHook((options) => {
    if (visitorToken) {
      options.headers['Visitor-Token'] = visitorToken;
    }
    return options;
  });
};

export const fetchSettings = async (token, clientId) => {
  try {
    getGatewayOptions(token, clientId);
    const req = new GetUserDataRequest();
    const LivechatGateway = new Livechat(Gateway);
    const response = await LivechatGateway.GetUserData(req);
    const data = response.toJSON();
    const settingsMap = data.Settings;
    if (settingsMap && settingsMap.color) {
      return { color: settingsMap.color.Value };
    }
    return { color: '' };
  } catch (err) {
    return { color: '' };
  }
};

export const GetCSATForm = async (roomId, visitorToken, clientId) => {
  try {
    getGatewayOptions(visitorToken, clientId);
    const getCSATFormRequest = new GetCSATFormRequest();
    getCSATFormRequest.setRoomid(roomId);
    const Feedbackgateway = new UserFeedback(Gateway);
    const response = await Feedbackgateway.GetCSATForm(getCSATFormRequest);
    const data = response.toJSON();
    return data.CSATForm;
  } catch (error) {
    return catchError(error);
  }
};

export const SubmitCSATForm = async (clientId, roomId, visitorToken, data) => {
  try {
    getGatewayOptions(visitorToken, clientId);
    const submitCSATFormRequest = new SubmitCSATFormRequest();
    const variableMap = submitCSATFormRequest.getVariablesMap();
    submitCSATFormRequest.setRoomid(roomId);
    submitCSATFormRequest.setScore(data.Score || 0);
    submitCSATFormRequest.setComment(data.Comment || '');
    Object.keys(data.Variables).forEach((value) => {
      if (value && data.Variables[value]) {
        variableMap.set(value, `${data.Variables[value]}`);
      }
    });
    const Feedbackgateway = new UserFeedback(Gateway);

    const response = await Feedbackgateway.SubmitCSATForm(
      submitCSATFormRequest
    );
    return response;
  } catch (error) {
    return catchError(error);
  }
};
