import style from './style.css';

const Footer = () => {
  return (
    <div className={`${style.footerItem}`}>
      <img
        src="/assets/icons/bolt.svg"
        alt=""
        className={style.footer}
        type="text/css"
      />
      <div className={style.footerText}>by</div>
      <img
        src="/assets/icons/verloop-logo.jpg"
        alt=""
        className={style.footerLogo}
      />
      <div className={style.footerText}>Verloop.io</div>
    </div>
  );
};

export default Footer;
