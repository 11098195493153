import style from './style.css';

const Header = (props) => {
  if (typeof window === 'undefined') return;
  const themeColor =
    (props && props.theme && props.theme.color) ||
    window.sessionStorage.getItem('themeColor');
  const themeTitle =
    (props && props.theme && props.theme.title) ||
    window.sessionStorage.getItem('themeTitle');
  if (!themeColor && !themeTitle) {
    // user directly landed on /submit page without submitting feedback
    return;
  }
  return (
    <div className={style.header} style={{ color: themeColor }}>
      {themeTitle}
    </div>
  );
};

export default Header;
