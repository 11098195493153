import { useState, useRef, useContext } from 'preact/hooks';
import style from './style.css';
import { HomeContext } from '../../pages/home';

const CsatIconsList = (props) => {
  const img_0 = useRef(null);
  const img_1 = useRef(null);
  const img_2 = useRef(null);
  const img_3 = useRef(null);
  const img_4 = useRef(null);
  const [showForm, setShowForm] = useState(false);
  const [lastSelectedEmoji, setLastSelectedEmoji] = useState(null);

  const { setSelectedEmoji, setSelectedEmojiScore } = useContext(HomeContext);

  const imgMap = [
    {
      id: 'img_0',
      src: '/assets/icons/angry.svg',
      ref: img_0,
      textId: 'imgtext_0',
      text: 'Terrible!',
      score: 1,
    },
    {
      id: 'img_1',
      src: '/assets/icons/sad.svg',
      ref: img_1,
      textId: 'imgtext_1',
      text: 'Bad!',
      score: 2,
    },
    {
      id: 'img_2',
      src: '/assets/icons/disappointed.svg',
      ref: img_2,
      textId: 'imgtext_2',
      text: 'Meh!',
      score: 3,
    },
    {
      id: 'img_3',
      src: '/assets/icons/happy.svg',
      ref: img_3,
      textId: 'imgtext_3',
      text: 'Good!',
      score: 4,
    },
    {
      id: 'img_4',
      src: '/assets/icons/in-love.svg',
      ref: img_4,
      textId: 'imgtext_4',
      text: 'Awesome!',
      score: 5,
    },
  ];

  function handleImageClick(selectedImg) {
    setShowForm(true);
    const selectedImgId =
      selectedImg && selectedImg.current && selectedImg.current.id;
    const selectedEmojiScore = imgMap.find((img) => img.id === selectedImgId)
      .score;
    setSelectedEmojiScore(selectedEmojiScore);
    setSelectedEmoji(selectedImg);
    const selecetedImgElement = selectedImg.current;
    if (!lastSelectedEmoji || lastSelectedEmoji !== selecetedImgElement.id) {
      selecetedImgElement.style.transform = 'scale(1.5)';
      selecetedImgElement.style.transition = 'all 0.3s ease';
    }
    imgMap.forEach((img) => {
      const imgElement = document.getElementById(img.id);
      const imgTextElement = document.getElementById(img.textId);
      if (img.id === selecetedImgElement.id) {
        imgTextElement.style.display = 'flex';
        imgTextElement.style['justify-content'] = 'center';
        return;
      }
      imgElement.style.transform = 'none';
      // imgElement.style.width = '40px';
      // imgElement.style.width = imgElement.style.width  - '10px';
      if (imgTextElement) {
        imgTextElement.style.display = 'none';
      }
      setLastSelectedEmoji(selecetedImgElement.id);
    });
  }

  return (
    <div className={`${style.emojis}`}>
      {imgMap.map((img) => {
        return (
          <div className={`${style.feedbackItem} `}>
            <div className={`${style.emoji}`}>
              <img
                id={img.id}
                alt={img.text}
                onClick={() => handleImageClick(img.ref)}
                ref={img.ref}
                src={img.src}
              />
            </div>
            <div id={img.textId} className={style.imgtext}>
              {img.text}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CsatIconsList;
