import { useState, useContext } from 'preact/hooks';
import { HomeContext } from '../../pages/home';
import style from './style.css';

const emojiTextMap = {
  img_0: 'Sorry to hear that. Let us know what can be improved? (Optional)',
  img_1: 'Sorry to hear that. Let us know what can be improved? (Optional)',
  img_2: 'Sorry to hear that. Let us know what can be improved? (Optional)',
  img_3: 'Thats Great! What did you like about us! \n(Optional)',
  img_4: 'Thats Great! What did you like about us! \n(Optional)',
};

const Comment = () => {
  const [inputValue, setInputValue] = useState(null);
  const { selectedEmoji, setComment, setSelectedEmojiScore } = useContext(
    HomeContext
  );
  const selectedEmojiId =
    selectedEmoji && selectedEmoji.current && selectedEmoji.current.id;
  const placeholderText = emojiTextMap[selectedEmojiId];

  const inputBlur = (input) => {
    setComment(input);
  };
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div className={`${style.textareaDefault}`}>
      {selectedEmoji !== null ? (
        <textarea
          className={`${style.textarea}`}
          autoFocus
          placeholder={placeholderText}
          value={inputValue}
          onChange={handleChange}
          onBlur={() => inputBlur(inputValue)}
          wrap="soft"
        />
      ) : null}
    </div>
  );
};

export default Comment;
