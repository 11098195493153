import { Fragment } from 'preact';
import { useContext } from 'preact/hooks';
import style from './style.css';
import { HomeContext } from '../../pages/home';

const SubmitButton = (props) => {
  const { comment, selectedEmojiScore } = useContext(HomeContext);
  return (
    <div className={style.submitBtn}>
      {selectedEmojiScore !== null ? (
        <div
          className={`${style.rectangle}`}
          style={{ backgroundColor: props.theme.color }}
          onClick={() =>
            props.submitFeedbackDataAndRedirect({
              score: selectedEmojiScore,
              comment,
            })
          }
        >
          Submit
        </div>
      ) : null}
    </div>
  );
};

export default SubmitButton;
