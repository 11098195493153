import { route } from 'preact-router';
import { useState } from 'preact/hooks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SubmitCSATForm } from '../../api';
import { ERROR_MSG } from '../constants';
import ButtonField from './ButtonField';
import RatingField from './RatingField';
import './style.css';
import TextField from './TextField';

const CSATBody = ({ form, room, vt, clientId, themeColor }) => {
  const [csatForm, setCSATForm] = useState(form);
  const [required, setRequired] = useState({});

  const getExtractedData = () => {
    const extractedData = csatForm.FormFields.reduce((acc, result) => {
      if (result.RatingField) {
        const { value, comment } = result;
        const {
          CustomVariableForComment,
          CustomVariableForScore,
          IsPrimaryScore,
          UseFollowUpForComments,
        } = result.RatingField;
        if (!CustomVariableForComment && !CustomVariableForScore) return;
        if (IsPrimaryScore || UseFollowUpForComments) {
          if (IsPrimaryScore) {
            acc = {
              ...acc,
              Score: value,
              Variables: {
                ...(acc.Variables || {}),
                [CustomVariableForScore]: value,
                [CustomVariableForComment]: comment,
              },
            };
          }
          if (UseFollowUpForComments) {
            acc = {
              ...acc,
              Comment: comment,
              Variables: {
                ...(acc.Variables || {}),
                [CustomVariableForScore]: value,
                [CustomVariableForComment]: comment,
              },
            };
          }
          return acc;
        }
        return {
          ...acc,
          Variables: {
            ...(acc.Variables || {}),
            [CustomVariableForScore]: value,
            [CustomVariableForComment]: comment,
          },
        };
      }
      if (result.FreeTextField) {
        const { CustomVariable } = result.FreeTextField;
        if (!CustomVariable) return;
        return {
          ...acc,
          Variables: {
            ...(acc.Variables || {}),
            [CustomVariable]: result.value,
          },
        };
      }
      if (result.ButtonsField) {
        const { CustomVariable } = result.ButtonsField;
        if (!CustomVariable) return;
        const value =
          result.value && result.value.length ? result.value.join(',') : '';
        return {
          ...acc,
          Variables: {
            ...(acc.Variables || {}),
            [CustomVariable]: value,
          },
        };
      }
    }, {});
    return extractedData;
  };

  const getFollowQues = (field, score) => {
    if (!field || !score || !field.RatingField) return undefined;
    const result = field.RatingField.Question.find(
      (ques) => ques.Score === score
    );
    return result;
  };

  const validateForm = () => {
    const validationErrors = csatForm.FormFields.reduce((acc, field) => {
      if (field.FreeTextField) {
        if (field.IsMandatory && !field.value) {
          return { ...acc, [field.Id]: 'required' };
        }
      } else if (field.ButtonsField) {
        if (field.IsMandatory && (!field.value || field.value.length === 0)) {
          return { ...acc, [field.Id]: 'required' };
        }
      } else if (field.RatingField) {
        const RatingField = field.RatingField;
        if (field.IsMandatory) {
          if (!field.value) {
            acc = { ...acc, [field.Id]: 'required' };
          }
        }
        if (
          RatingField.IsFollowUpMandatory &&
          field.value <= RatingField.MinScoreForFollowUp &&
          getFollowQues(field, field.value)
        ) {
          if (RatingField.UseFollowUpForComments && !field.comment) {
            acc = { ...acc, [field.Id + 'followup']: 'required' };
          } else {
            const followUpVariable = RatingField.CustomVariableForComment;
            if (followUpVariable && !field.comment) {
              acc = {
                ...acc,
                [field.Id + 'followup']: 'required',
              };
            }
          }
        }
      }
      return acc;
    }, {});
    return validationErrors;
  };

  const handleSubmit = () => {
    const requiredFieldIds = validateForm();
    if (Object.keys(requiredFieldIds).length === 0) {
      const data = getExtractedData();
      SubmitCSATForm(clientId, room, vt, data)
        .then(() => {
          route('/submit', true);
        })
        .catch((err) => {
          const errMsg = err.message || ERROR_MSG;
          toast.error(errMsg, {
            theme: 'colored',
            hideProgressBar: true,
          });
        });
    } else {
      setRequired(requiredFieldIds);
    }
  };

  return (
    <div className="wrapper">
      <div className="subHeading">{form.Description}</div>
      <div className="fiedWrapper">
        {form.FormFields.map((field) => {
          if (field.RatingField) {
            return (
              <RatingField
                key={field.Id}
                fieldData={field}
                csatForm={csatForm}
                setCSATForm={setCSATForm}
                required={required}
              />
            );
          }
          if (field.FreeTextField) {
            return (
              <TextField
                key={field.Id}
                fieldData={field}
                csatForm={csatForm}
                setCSATForm={setCSATForm}
                required={required}
              />
            );
          }
          if (field.ButtonsField) {
            return (
              <ButtonField
                key={field.Id}
                fieldData={field}
                csatForm={csatForm}
                setCSATForm={setCSATForm}
                required={required}
              />
            );
          }
          return null;
        })}
      </div>
      <input
        type="submit"
        className="submit"
        value="Submit feedback"
        onClick={handleSubmit}
        style={themeColor ? { background: themeColor } : '#db3627'}
      />
      <ToastContainer />
    </div>
  );
};

export default CSATBody;
