import { useEffect, useState } from 'preact/hooks';
import './style.css';

const ButtonField = ({ fieldData, csatForm, setCSATForm, required }) => {
  const [selected, setSelected] = useState([]);
  const [isRequired, setIsRequired] = useState(false);

  const handleButtonClick = (e) => {
    const selectedButton = e.target.value;
    const index = selected.findIndex((btn) => btn === selectedButton);
    if (index !== -1) {
      selected.splice(index, 1);
      setSelected([...selected]);
    } else {
      setSelected([...selected, selectedButton]);
    }
  };

  useEffect(() => {
    csatForm.FormFields.map((field) => {
      if (field.Id === fieldData.Id) {
        fieldData['value'] = selected;
      }
      return csatForm;
    });
    setCSATForm(csatForm);
  }, [selected]);

  const isSelected = (button) => {
    return selected.includes(button);
  };

  useEffect(() => {
    setIsRequired(required.hasOwnProperty(fieldData.Id));
  }, [required]);

  return (
    <div className="buttonContainer">
      <div className="title">
        {fieldData.ButtonsField.Title}
        {isRequired && <span className="required"> * required</span>}
        {!fieldData.IsMandatory ? <span> (optional)</span> : ''}
      </div>
      <div className="buttonWrapper" id={fieldData.ButtonsField.Id}>
        {fieldData.ButtonsField.Buttons.map((button) => {
          return (
            <button
              type="button"
              className={`buttonField ${
                isSelected(button) ? 'activeButton' : ''
              }`}
              onClick={handleButtonClick}
              value={button}
            >
              {button}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ButtonField;
